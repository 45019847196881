<script setup lang="ts">
import { computed } from 'vue';

import { UiButton, UiGlobalHeader } from '@/components/ui';
import { ROUTES } from '@/enums';

import { ErrorIcon } from '../components';
import { BusinessErrorProps } from '../enums';
import { navigateTo } from '#app';

import { useRouter } from '#imports';

const props = defineProps<Omit<BusinessErrorProps, 'layout'>>();

const router = useRouter();

const handleClickButton = () => {
  if (props.backTo) {
    router.push({ name: props.backTo });
  } else {
    navigateTo('/');
  }
};

const buttonLabel = computed(() => props.backToText ?? 'ホームへ');
</script>

<template>
  <div class="page">
    <UiGlobalHeader title="エラー" />
    <main class="body">
      <div class="content">
        <div class="heading">
          <ErrorIcon :title="title" />
          <div class="messages">
            <p>{{ message }}</p>
          </div>
        </div>
        <div class="footer-button">
          <UiButton @click="handleClickButton">{{ buttonLabel }}</UiButton>
        </div>
      </div>
    </main>
  </div>
</template>

<style lang="scss" scoped>
.page {
  height: 100dvh;
}
.body {
  height: calc(100% - 44px);
}
.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 72px;
}
.heading {
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.messages {
  margin-top: 16px;
  white-space: pre-wrap;
  font-size: 1.4rem;
}
.footer-button {
  width: 100%;
  text-align: center;
}
</style>
