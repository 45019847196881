import { default as index17EcTN28VEMeta } from "/builds/zone/dev/web/zone-service-top/pages/1second/index.vue?macro=true";
import { default as apppMqQkheQGPMeta } from "/builds/zone/dev/web/zone-service-top/pages/about/app.vue?macro=true";
import { default as flow4hHR9wq4MwMeta } from "/builds/zone/dev/web/zone-service-top/pages/about/flow.vue?macro=true";
import { default as index0TYNaJCDUaMeta } from "/builds/zone/dev/web/zone-service-top/pages/about/index.vue?macro=true";
import { default as summaryeYB9WQVC3XMeta } from "/builds/zone/dev/web/zone-service-top/pages/about/summary.vue?macro=true";
import { default as indexzw4JaCWQ1yMeta } from "/builds/zone/dev/web/zone-service-top/pages/app_detail/index.vue?macro=true";
import { default as banklistCSmrTFTLuFMeta } from "/builds/zone/dev/web/zone-service-top/pages/apply/banklist.vue?macro=true";
import { default as certificatel3sXVGMORNMeta } from "/builds/zone/dev/web/zone-service-top/pages/apply/certificate.vue?macro=true";
import { default as docsvI7ASrtpF3Meta } from "/builds/zone/dev/web/zone-service-top/pages/apply/docs.vue?macro=true";
import { default as identificationuODUp7z2bHMeta } from "/builds/zone/dev/web/zone-service-top/pages/apply/identification.vue?macro=true";
import { default as indexGGJghmsEZmMeta } from "/builds/zone/dev/web/zone-service-top/pages/apply/index.vue?macro=true";
import { default as column001FjU88ogIHyMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column001.vue?macro=true";
import { default as column002AejDC1ul4JMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column002.vue?macro=true";
import { default as column003vwtOjwhS9bMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column003.vue?macro=true";
import { default as column004zAnNKu8n3WMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column004.vue?macro=true";
import { default as column005cxuqxiaJukMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column005.vue?macro=true";
import { default as column006ax2wRyQcloMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column006.vue?macro=true";
import { default as column007x6BTlbHveYMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column007.vue?macro=true";
import { default as column008i0oOt4pvrYMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column008.vue?macro=true";
import { default as column009GJnEyUOKmjMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column009.vue?macro=true";
import { default as column010gdl6NLEaKZMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column010.vue?macro=true";
import { default as column011QWbliUxJ7IMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column011.vue?macro=true";
import { default as column012A8oKThXsRIMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column012.vue?macro=true";
import { default as column013p3NnfY0rISMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column013.vue?macro=true";
import { default as column014GtheoSGqIfMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column014.vue?macro=true";
import { default as column015nwsvsWFusvMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column015.vue?macro=true";
import { default as indexKT0W990WLnMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/index.vue?macro=true";
import { default as completeHIYvNlfzIFMeta } from "/builds/zone/dev/web/zone-service-top/pages/bank_transfer/complete.vue?macro=true";
import { default as failureAlJTIbXAGJMeta } from "/builds/zone/dev/web/zone-service-top/pages/bank_transfer/failure.vue?macro=true";
import { default as interruptionvuHn0HhP2aMeta } from "/builds/zone/dev/web/zone-service-top/pages/bank_transfer/interruption.vue?macro=true";
import { default as startsPaqN89CA9Meta } from "/builds/zone/dev/web/zone-service-top/pages/bank_transfer/start.vue?macro=true";
import { default as banktrowijk8RXMeta } from "/builds/zone/dev/web/zone-service-top/pages/borrow/bank.vue?macro=true";
import { default as benefitNSVYNrJex5Meta } from "/builds/zone/dev/web/zone-service-top/pages/borrow/benefit.vue?macro=true";
import { default as d_payment7UG3TcHHWKMeta } from "/builds/zone/dev/web/zone-service-top/pages/borrow/d_payment.vue?macro=true";
import { default as indexL7tdHNEec0Meta } from "/builds/zone/dev/web/zone-service-top/pages/borrow/index.vue?macro=true";
import { default as limitsBk40Vv3zsMeta } from "/builds/zone/dev/web/zone-service-top/pages/borrow/limit.vue?macro=true";
import { default as rategCWSX05opgMeta } from "/builds/zone/dev/web/zone-service-top/pages/borrow/rate.vue?macro=true";
import { default as indexf6Pm7Oe7QgMeta } from "/builds/zone/dev/web/zone-service-top/pages/borrow/simulation/index.vue?macro=true";
import { default as planGnCp2Y2MB1Meta } from "/builds/zone/dev/web/zone-service-top/pages/borrow/simulation/plan.vue?macro=true";
import { default as resultJP619i7WyHMeta } from "/builds/zone/dev/web/zone-service-top/pages/borrow/simulation/result.vue?macro=true";
import { default as _91id_93qw74Pe6mKVMeta } from "/builds/zone/dev/web/zone-service-top/pages/campaign/[id].vue?macro=true";
import { default as indexTcBNUuq6c1Meta } from "/builds/zone/dev/web/zone-service-top/pages/campaign/index.vue?macro=true";
import { default as _91id_93kGok1LhqRGMeta } from "/builds/zone/dev/web/zone-service-top/pages/faq/[category]/[id].vue?macro=true";
import { default as indexQZJshyAwyVMeta } from "/builds/zone/dev/web/zone-service-top/pages/faq/[category]/index.vue?macro=true";
import { default as indexu5dCvgCKyJMeta } from "/builds/zone/dev/web/zone-service-top/pages/faq/index.vue?macro=true";
import { default as indexocBudGvlk6Meta } from "/builds/zone/dev/web/zone-service-top/pages/index.vue?macro=true";
import { default as _91id_93afuH7lTVdUMeta } from "/builds/zone/dev/web/zone-service-top/pages/info/[id].vue?macro=true";
import { default as indexA9fYEzEnixMeta } from "/builds/zone/dev/web/zone-service-top/pages/info/index.vue?macro=true";
import { default as chat1kyklZzFYIMeta } from "/builds/zone/dev/web/zone-service-top/pages/inquiry/chat.vue?macro=true";
import { default as index1l6Leg8yUoMeta } from "/builds/zone/dev/web/zone-service-top/pages/inquiry/index.vue?macro=true";
import { default as d_payment_chgU041FaqR7VMeta } from "/builds/zone/dev/web/zone-service-top/pages/lp/d_payment_chg.vue?macro=true";
import { default as d_payment4BSWBQohP3Meta } from "/builds/zone/dev/web/zone-service-top/pages/lp/d_payment.vue?macro=true";
import { default as indexj4Wvqqw5S4Meta } from "/builds/zone/dev/web/zone-service-top/pages/redirect/index.vue?macro=true";
import { default as bankUTseD5emUxMeta } from "/builds/zone/dev/web/zone-service-top/pages/repay/bank.vue?macro=true";
import { default as bulletMUTXtdq43jMeta } from "/builds/zone/dev/web/zone-service-top/pages/repay/bullet.vue?macro=true";
import { default as d_paymentJAQUQgo7NwMeta } from "/builds/zone/dev/web/zone-service-top/pages/repay/d_payment.vue?macro=true";
import { default as indexkbtDGICTycMeta } from "/builds/zone/dev/web/zone-service-top/pages/repay/index.vue?macro=true";
import { default as repaymentAJmAHr3daKMeta } from "/builds/zone/dev/web/zone-service-top/pages/repay/repayment.vue?macro=true";
import { default as term6SAZFUNYGBMeta } from "/builds/zone/dev/web/zone-service-top/pages/repay/term.vue?macro=true";
import { default as indexoE6SMTpPM9Meta } from "/builds/zone/dev/web/zone-service-top/pages/terms/index.vue?macro=true";
import { default as _91id_93ZANivwVtvNMeta } from "/builds/zone/dev/web/zone-service-top/pages/termslist/[id].vue?macro=true";
import { default as indexhaq5NeeyQJMeta } from "/builds/zone/dev/web/zone-service-top/pages/termslist/index.vue?macro=true";
export default [
  {
    name: index17EcTN28VEMeta?.name ?? "1second",
    path: index17EcTN28VEMeta?.path ?? "/1second",
    meta: index17EcTN28VEMeta || {},
    alias: index17EcTN28VEMeta?.alias || [],
    redirect: index17EcTN28VEMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/1second/index.vue").then(m => m.default || m)
  },
  {
    name: apppMqQkheQGPMeta?.name ?? "about-app",
    path: apppMqQkheQGPMeta?.path ?? "/about/app",
    meta: apppMqQkheQGPMeta || {},
    alias: apppMqQkheQGPMeta?.alias || [],
    redirect: apppMqQkheQGPMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/about/app.vue").then(m => m.default || m)
  },
  {
    name: flow4hHR9wq4MwMeta?.name ?? "about-flow",
    path: flow4hHR9wq4MwMeta?.path ?? "/about/flow",
    meta: flow4hHR9wq4MwMeta || {},
    alias: flow4hHR9wq4MwMeta?.alias || [],
    redirect: flow4hHR9wq4MwMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/about/flow.vue").then(m => m.default || m)
  },
  {
    name: index0TYNaJCDUaMeta?.name ?? "about",
    path: index0TYNaJCDUaMeta?.path ?? "/about",
    meta: index0TYNaJCDUaMeta || {},
    alias: index0TYNaJCDUaMeta?.alias || [],
    redirect: index0TYNaJCDUaMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: summaryeYB9WQVC3XMeta?.name ?? "about-summary",
    path: summaryeYB9WQVC3XMeta?.path ?? "/about/summary",
    meta: summaryeYB9WQVC3XMeta || {},
    alias: summaryeYB9WQVC3XMeta?.alias || [],
    redirect: summaryeYB9WQVC3XMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/about/summary.vue").then(m => m.default || m)
  },
  {
    name: indexzw4JaCWQ1yMeta?.name ?? "app_detail",
    path: indexzw4JaCWQ1yMeta?.path ?? "/app_detail",
    meta: indexzw4JaCWQ1yMeta || {},
    alias: indexzw4JaCWQ1yMeta?.alias || [],
    redirect: indexzw4JaCWQ1yMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/app_detail/index.vue").then(m => m.default || m)
  },
  {
    name: banklistCSmrTFTLuFMeta?.name ?? "apply-banklist",
    path: banklistCSmrTFTLuFMeta?.path ?? "/apply/banklist",
    meta: banklistCSmrTFTLuFMeta || {},
    alias: banklistCSmrTFTLuFMeta?.alias || [],
    redirect: banklistCSmrTFTLuFMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/apply/banklist.vue").then(m => m.default || m)
  },
  {
    name: certificatel3sXVGMORNMeta?.name ?? "apply-certificate",
    path: certificatel3sXVGMORNMeta?.path ?? "/apply/certificate",
    meta: certificatel3sXVGMORNMeta || {},
    alias: certificatel3sXVGMORNMeta?.alias || [],
    redirect: certificatel3sXVGMORNMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/apply/certificate.vue").then(m => m.default || m)
  },
  {
    name: docsvI7ASrtpF3Meta?.name ?? "apply-docs",
    path: docsvI7ASrtpF3Meta?.path ?? "/apply/docs",
    meta: docsvI7ASrtpF3Meta || {},
    alias: docsvI7ASrtpF3Meta?.alias || [],
    redirect: docsvI7ASrtpF3Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/apply/docs.vue").then(m => m.default || m)
  },
  {
    name: identificationuODUp7z2bHMeta?.name ?? "apply-identification",
    path: identificationuODUp7z2bHMeta?.path ?? "/apply/identification",
    meta: identificationuODUp7z2bHMeta || {},
    alias: identificationuODUp7z2bHMeta?.alias || [],
    redirect: identificationuODUp7z2bHMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/apply/identification.vue").then(m => m.default || m)
  },
  {
    name: indexGGJghmsEZmMeta?.name ?? "apply",
    path: indexGGJghmsEZmMeta?.path ?? "/apply",
    meta: indexGGJghmsEZmMeta || {},
    alias: indexGGJghmsEZmMeta?.alias || [],
    redirect: indexGGJghmsEZmMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/apply/index.vue").then(m => m.default || m)
  },
  {
    name: column001FjU88ogIHyMeta?.name ?? "article-column001",
    path: column001FjU88ogIHyMeta?.path ?? "/article/column001",
    meta: column001FjU88ogIHyMeta || {},
    alias: column001FjU88ogIHyMeta?.alias || [],
    redirect: column001FjU88ogIHyMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column001.vue").then(m => m.default || m)
  },
  {
    name: column002AejDC1ul4JMeta?.name ?? "article-column002",
    path: column002AejDC1ul4JMeta?.path ?? "/article/column002",
    meta: column002AejDC1ul4JMeta || {},
    alias: column002AejDC1ul4JMeta?.alias || [],
    redirect: column002AejDC1ul4JMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column002.vue").then(m => m.default || m)
  },
  {
    name: column003vwtOjwhS9bMeta?.name ?? "article-column003",
    path: column003vwtOjwhS9bMeta?.path ?? "/article/column003",
    meta: column003vwtOjwhS9bMeta || {},
    alias: column003vwtOjwhS9bMeta?.alias || [],
    redirect: column003vwtOjwhS9bMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column003.vue").then(m => m.default || m)
  },
  {
    name: column004zAnNKu8n3WMeta?.name ?? "article-column004",
    path: column004zAnNKu8n3WMeta?.path ?? "/article/column004",
    meta: column004zAnNKu8n3WMeta || {},
    alias: column004zAnNKu8n3WMeta?.alias || [],
    redirect: column004zAnNKu8n3WMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column004.vue").then(m => m.default || m)
  },
  {
    name: column005cxuqxiaJukMeta?.name ?? "article-column005",
    path: column005cxuqxiaJukMeta?.path ?? "/article/column005",
    meta: column005cxuqxiaJukMeta || {},
    alias: column005cxuqxiaJukMeta?.alias || [],
    redirect: column005cxuqxiaJukMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column005.vue").then(m => m.default || m)
  },
  {
    name: column006ax2wRyQcloMeta?.name ?? "article-column006",
    path: column006ax2wRyQcloMeta?.path ?? "/article/column006",
    meta: column006ax2wRyQcloMeta || {},
    alias: column006ax2wRyQcloMeta?.alias || [],
    redirect: column006ax2wRyQcloMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column006.vue").then(m => m.default || m)
  },
  {
    name: column007x6BTlbHveYMeta?.name ?? "article-column007",
    path: column007x6BTlbHveYMeta?.path ?? "/article/column007",
    meta: column007x6BTlbHveYMeta || {},
    alias: column007x6BTlbHveYMeta?.alias || [],
    redirect: column007x6BTlbHveYMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column007.vue").then(m => m.default || m)
  },
  {
    name: column008i0oOt4pvrYMeta?.name ?? "article-column008",
    path: column008i0oOt4pvrYMeta?.path ?? "/article/column008",
    meta: column008i0oOt4pvrYMeta || {},
    alias: column008i0oOt4pvrYMeta?.alias || [],
    redirect: column008i0oOt4pvrYMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column008.vue").then(m => m.default || m)
  },
  {
    name: column009GJnEyUOKmjMeta?.name ?? "article-column009",
    path: column009GJnEyUOKmjMeta?.path ?? "/article/column009",
    meta: column009GJnEyUOKmjMeta || {},
    alias: column009GJnEyUOKmjMeta?.alias || [],
    redirect: column009GJnEyUOKmjMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column009.vue").then(m => m.default || m)
  },
  {
    name: column010gdl6NLEaKZMeta?.name ?? "article-column010",
    path: column010gdl6NLEaKZMeta?.path ?? "/article/column010",
    meta: column010gdl6NLEaKZMeta || {},
    alias: column010gdl6NLEaKZMeta?.alias || [],
    redirect: column010gdl6NLEaKZMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column010.vue").then(m => m.default || m)
  },
  {
    name: column011QWbliUxJ7IMeta?.name ?? "article-column011",
    path: column011QWbliUxJ7IMeta?.path ?? "/article/column011",
    meta: column011QWbliUxJ7IMeta || {},
    alias: column011QWbliUxJ7IMeta?.alias || [],
    redirect: column011QWbliUxJ7IMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column011.vue").then(m => m.default || m)
  },
  {
    name: column012A8oKThXsRIMeta?.name ?? "article-column012",
    path: column012A8oKThXsRIMeta?.path ?? "/article/column012",
    meta: column012A8oKThXsRIMeta || {},
    alias: column012A8oKThXsRIMeta?.alias || [],
    redirect: column012A8oKThXsRIMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column012.vue").then(m => m.default || m)
  },
  {
    name: column013p3NnfY0rISMeta?.name ?? "article-column013",
    path: column013p3NnfY0rISMeta?.path ?? "/article/column013",
    meta: column013p3NnfY0rISMeta || {},
    alias: column013p3NnfY0rISMeta?.alias || [],
    redirect: column013p3NnfY0rISMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column013.vue").then(m => m.default || m)
  },
  {
    name: column014GtheoSGqIfMeta?.name ?? "article-column014",
    path: column014GtheoSGqIfMeta?.path ?? "/article/column014",
    meta: column014GtheoSGqIfMeta || {},
    alias: column014GtheoSGqIfMeta?.alias || [],
    redirect: column014GtheoSGqIfMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column014.vue").then(m => m.default || m)
  },
  {
    name: column015nwsvsWFusvMeta?.name ?? "article-column015",
    path: column015nwsvsWFusvMeta?.path ?? "/article/column015",
    meta: column015nwsvsWFusvMeta || {},
    alias: column015nwsvsWFusvMeta?.alias || [],
    redirect: column015nwsvsWFusvMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column015.vue").then(m => m.default || m)
  },
  {
    name: indexKT0W990WLnMeta?.name ?? "article",
    path: indexKT0W990WLnMeta?.path ?? "/article",
    meta: indexKT0W990WLnMeta || {},
    alias: indexKT0W990WLnMeta?.alias || [],
    redirect: indexKT0W990WLnMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/index.vue").then(m => m.default || m)
  },
  {
    name: completeHIYvNlfzIFMeta?.name ?? "bank_transfer-complete",
    path: completeHIYvNlfzIFMeta?.path ?? "/bank_transfer/complete",
    meta: completeHIYvNlfzIFMeta || {},
    alias: completeHIYvNlfzIFMeta?.alias || [],
    redirect: completeHIYvNlfzIFMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/bank_transfer/complete.vue").then(m => m.default || m)
  },
  {
    name: failureAlJTIbXAGJMeta?.name ?? "bank_transfer-failure",
    path: failureAlJTIbXAGJMeta?.path ?? "/bank_transfer/failure",
    meta: failureAlJTIbXAGJMeta || {},
    alias: failureAlJTIbXAGJMeta?.alias || [],
    redirect: failureAlJTIbXAGJMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/bank_transfer/failure.vue").then(m => m.default || m)
  },
  {
    name: interruptionvuHn0HhP2aMeta?.name ?? "bank_transfer-interruption",
    path: interruptionvuHn0HhP2aMeta?.path ?? "/bank_transfer/interruption",
    meta: interruptionvuHn0HhP2aMeta || {},
    alias: interruptionvuHn0HhP2aMeta?.alias || [],
    redirect: interruptionvuHn0HhP2aMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/bank_transfer/interruption.vue").then(m => m.default || m)
  },
  {
    name: startsPaqN89CA9Meta?.name ?? "bank_transfer-start",
    path: startsPaqN89CA9Meta?.path ?? "/bank_transfer/start",
    meta: startsPaqN89CA9Meta || {},
    alias: startsPaqN89CA9Meta?.alias || [],
    redirect: startsPaqN89CA9Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/bank_transfer/start.vue").then(m => m.default || m)
  },
  {
    name: banktrowijk8RXMeta?.name ?? "borrow-bank",
    path: banktrowijk8RXMeta?.path ?? "/borrow/bank",
    meta: banktrowijk8RXMeta || {},
    alias: banktrowijk8RXMeta?.alias || [],
    redirect: banktrowijk8RXMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/borrow/bank.vue").then(m => m.default || m)
  },
  {
    name: benefitNSVYNrJex5Meta?.name ?? "borrow-benefit",
    path: benefitNSVYNrJex5Meta?.path ?? "/borrow/benefit",
    meta: benefitNSVYNrJex5Meta || {},
    alias: benefitNSVYNrJex5Meta?.alias || [],
    redirect: benefitNSVYNrJex5Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/borrow/benefit.vue").then(m => m.default || m)
  },
  {
    name: d_payment7UG3TcHHWKMeta?.name ?? "borrow-d_payment",
    path: d_payment7UG3TcHHWKMeta?.path ?? "/borrow/d_payment",
    meta: d_payment7UG3TcHHWKMeta || {},
    alias: d_payment7UG3TcHHWKMeta?.alias || [],
    redirect: d_payment7UG3TcHHWKMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/borrow/d_payment.vue").then(m => m.default || m)
  },
  {
    name: indexL7tdHNEec0Meta?.name ?? "borrow",
    path: indexL7tdHNEec0Meta?.path ?? "/borrow",
    meta: indexL7tdHNEec0Meta || {},
    alias: indexL7tdHNEec0Meta?.alias || [],
    redirect: indexL7tdHNEec0Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/borrow/index.vue").then(m => m.default || m)
  },
  {
    name: limitsBk40Vv3zsMeta?.name ?? "borrow-limit",
    path: limitsBk40Vv3zsMeta?.path ?? "/borrow/limit",
    meta: limitsBk40Vv3zsMeta || {},
    alias: limitsBk40Vv3zsMeta?.alias || [],
    redirect: limitsBk40Vv3zsMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/borrow/limit.vue").then(m => m.default || m)
  },
  {
    name: rategCWSX05opgMeta?.name ?? "borrow-rate",
    path: rategCWSX05opgMeta?.path ?? "/borrow/rate",
    meta: rategCWSX05opgMeta || {},
    alias: rategCWSX05opgMeta?.alias || [],
    redirect: rategCWSX05opgMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/borrow/rate.vue").then(m => m.default || m)
  },
  {
    name: indexf6Pm7Oe7QgMeta?.name ?? "borrow-simulation",
    path: indexf6Pm7Oe7QgMeta?.path ?? "/borrow/simulation",
    meta: indexf6Pm7Oe7QgMeta || {},
    alias: indexf6Pm7Oe7QgMeta?.alias || [],
    redirect: indexf6Pm7Oe7QgMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/borrow/simulation/index.vue").then(m => m.default || m)
  },
  {
    name: planGnCp2Y2MB1Meta?.name ?? "borrow-simulation-plan",
    path: planGnCp2Y2MB1Meta?.path ?? "/borrow/simulation/plan",
    meta: planGnCp2Y2MB1Meta || {},
    alias: planGnCp2Y2MB1Meta?.alias || [],
    redirect: planGnCp2Y2MB1Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/borrow/simulation/plan.vue").then(m => m.default || m)
  },
  {
    name: resultJP619i7WyHMeta?.name ?? "borrow-simulation-result",
    path: resultJP619i7WyHMeta?.path ?? "/borrow/simulation/result",
    meta: resultJP619i7WyHMeta || {},
    alias: resultJP619i7WyHMeta?.alias || [],
    redirect: resultJP619i7WyHMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/borrow/simulation/result.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qw74Pe6mKVMeta?.name ?? "campaign-id",
    path: _91id_93qw74Pe6mKVMeta?.path ?? "/campaign/:id()",
    meta: _91id_93qw74Pe6mKVMeta || {},
    alias: _91id_93qw74Pe6mKVMeta?.alias || [],
    redirect: _91id_93qw74Pe6mKVMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/campaign/[id].vue").then(m => m.default || m)
  },
  {
    name: indexTcBNUuq6c1Meta?.name ?? "campaign",
    path: indexTcBNUuq6c1Meta?.path ?? "/campaign",
    meta: indexTcBNUuq6c1Meta || {},
    alias: indexTcBNUuq6c1Meta?.alias || [],
    redirect: indexTcBNUuq6c1Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/campaign/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93kGok1LhqRGMeta?.name ?? "faq-category-id",
    path: _91id_93kGok1LhqRGMeta?.path ?? "/faq/:category()/:id()",
    meta: _91id_93kGok1LhqRGMeta || {},
    alias: _91id_93kGok1LhqRGMeta?.alias || [],
    redirect: _91id_93kGok1LhqRGMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/faq/[category]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexQZJshyAwyVMeta?.name ?? "faq-category",
    path: indexQZJshyAwyVMeta?.path ?? "/faq/:category()",
    meta: indexQZJshyAwyVMeta || {},
    alias: indexQZJshyAwyVMeta?.alias || [],
    redirect: indexQZJshyAwyVMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/faq/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexu5dCvgCKyJMeta?.name ?? "faq",
    path: indexu5dCvgCKyJMeta?.path ?? "/faq",
    meta: indexu5dCvgCKyJMeta || {},
    alias: indexu5dCvgCKyJMeta?.alias || [],
    redirect: indexu5dCvgCKyJMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: indexocBudGvlk6Meta?.name ?? "index",
    path: indexocBudGvlk6Meta?.path ?? "/",
    meta: indexocBudGvlk6Meta || {},
    alias: indexocBudGvlk6Meta?.alias || [],
    redirect: indexocBudGvlk6Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93afuH7lTVdUMeta?.name ?? "info-id",
    path: _91id_93afuH7lTVdUMeta?.path ?? "/info/:id()",
    meta: _91id_93afuH7lTVdUMeta || {},
    alias: _91id_93afuH7lTVdUMeta?.alias || [],
    redirect: _91id_93afuH7lTVdUMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/info/[id].vue").then(m => m.default || m)
  },
  {
    name: indexA9fYEzEnixMeta?.name ?? "info",
    path: indexA9fYEzEnixMeta?.path ?? "/info",
    meta: indexA9fYEzEnixMeta || {},
    alias: indexA9fYEzEnixMeta?.alias || [],
    redirect: indexA9fYEzEnixMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: chat1kyklZzFYIMeta?.name ?? "inquiry-chat",
    path: chat1kyklZzFYIMeta?.path ?? "/inquiry/chat",
    meta: chat1kyklZzFYIMeta || {},
    alias: chat1kyklZzFYIMeta?.alias || [],
    redirect: chat1kyklZzFYIMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/inquiry/chat.vue").then(m => m.default || m)
  },
  {
    name: index1l6Leg8yUoMeta?.name ?? "inquiry",
    path: index1l6Leg8yUoMeta?.path ?? "/inquiry",
    meta: index1l6Leg8yUoMeta || {},
    alias: index1l6Leg8yUoMeta?.alias || [],
    redirect: index1l6Leg8yUoMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/inquiry/index.vue").then(m => m.default || m)
  },
  {
    name: d_payment_chgU041FaqR7VMeta?.name ?? "lp-d_payment_chg",
    path: d_payment_chgU041FaqR7VMeta?.path ?? "/lp/d_payment_chg",
    meta: d_payment_chgU041FaqR7VMeta || {},
    alias: d_payment_chgU041FaqR7VMeta?.alias || [],
    redirect: d_payment_chgU041FaqR7VMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/lp/d_payment_chg.vue").then(m => m.default || m)
  },
  {
    name: d_payment4BSWBQohP3Meta?.name ?? "lp-d_payment",
    path: d_payment4BSWBQohP3Meta?.path ?? "/lp/d_payment",
    meta: d_payment4BSWBQohP3Meta || {},
    alias: d_payment4BSWBQohP3Meta?.alias || [],
    redirect: d_payment4BSWBQohP3Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/lp/d_payment.vue").then(m => m.default || m)
  },
  {
    name: indexj4Wvqqw5S4Meta?.name ?? "redirect",
    path: indexj4Wvqqw5S4Meta?.path ?? "/redirect",
    meta: indexj4Wvqqw5S4Meta || {},
    alias: indexj4Wvqqw5S4Meta?.alias || [],
    redirect: indexj4Wvqqw5S4Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/redirect/index.vue").then(m => m.default || m)
  },
  {
    name: bankUTseD5emUxMeta?.name ?? "repay-bank",
    path: bankUTseD5emUxMeta?.path ?? "/repay/bank",
    meta: bankUTseD5emUxMeta || {},
    alias: bankUTseD5emUxMeta?.alias || [],
    redirect: bankUTseD5emUxMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/repay/bank.vue").then(m => m.default || m)
  },
  {
    name: bulletMUTXtdq43jMeta?.name ?? "repay-bullet",
    path: bulletMUTXtdq43jMeta?.path ?? "/repay/bullet",
    meta: bulletMUTXtdq43jMeta || {},
    alias: bulletMUTXtdq43jMeta?.alias || [],
    redirect: bulletMUTXtdq43jMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/repay/bullet.vue").then(m => m.default || m)
  },
  {
    name: d_paymentJAQUQgo7NwMeta?.name ?? "repay-d_payment",
    path: d_paymentJAQUQgo7NwMeta?.path ?? "/repay/d_payment",
    meta: d_paymentJAQUQgo7NwMeta || {},
    alias: d_paymentJAQUQgo7NwMeta?.alias || [],
    redirect: d_paymentJAQUQgo7NwMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/repay/d_payment.vue").then(m => m.default || m)
  },
  {
    name: indexkbtDGICTycMeta?.name ?? "repay",
    path: indexkbtDGICTycMeta?.path ?? "/repay",
    meta: indexkbtDGICTycMeta || {},
    alias: indexkbtDGICTycMeta?.alias || [],
    redirect: indexkbtDGICTycMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/repay/index.vue").then(m => m.default || m)
  },
  {
    name: repaymentAJmAHr3daKMeta?.name ?? "repay-repayment",
    path: repaymentAJmAHr3daKMeta?.path ?? "/repay/repayment",
    meta: repaymentAJmAHr3daKMeta || {},
    alias: repaymentAJmAHr3daKMeta?.alias || [],
    redirect: repaymentAJmAHr3daKMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/repay/repayment.vue").then(m => m.default || m)
  },
  {
    name: term6SAZFUNYGBMeta?.name ?? "repay-term",
    path: term6SAZFUNYGBMeta?.path ?? "/repay/term",
    meta: term6SAZFUNYGBMeta || {},
    alias: term6SAZFUNYGBMeta?.alias || [],
    redirect: term6SAZFUNYGBMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/repay/term.vue").then(m => m.default || m)
  },
  {
    name: indexoE6SMTpPM9Meta?.name ?? "terms",
    path: indexoE6SMTpPM9Meta?.path ?? "/terms",
    meta: indexoE6SMTpPM9Meta || {},
    alias: indexoE6SMTpPM9Meta?.alias || [],
    redirect: indexoE6SMTpPM9Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ZANivwVtvNMeta?.name ?? "termslist-id",
    path: _91id_93ZANivwVtvNMeta?.path ?? "/termslist/:id()",
    meta: _91id_93ZANivwVtvNMeta || {},
    alias: _91id_93ZANivwVtvNMeta?.alias || [],
    redirect: _91id_93ZANivwVtvNMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/termslist/[id].vue").then(m => m.default || m)
  },
  {
    name: indexhaq5NeeyQJMeta?.name ?? "termslist",
    path: indexhaq5NeeyQJMeta?.path ?? "/termslist",
    meta: indexhaq5NeeyQJMeta || {},
    alias: indexhaq5NeeyQJMeta?.alias || [],
    redirect: indexhaq5NeeyQJMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/termslist/index.vue").then(m => m.default || m)
  }
]