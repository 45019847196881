<script setup lang="ts">
import { computed } from 'vue';

type IconName = keyof typeof ICON_COMPONENTS;
type IconSize = (typeof ICON_SIZES)[number];

type UiIconProps = {
  /**
   * 利用するアイコン名。
   */
  name: IconName;
  /**
   * アイコンのサイズ。
   */
  size?: IconSize;
  /**
   * trueの場合、hover時のスタイルを適用する。
   */
  hasAction?: boolean;
};

const props = withDefaults(defineProps<UiIconProps>(), {
  // 20px
  size: ICON_SIZES[1],
  hasAction: false,
});

const classes = computed(() => ({
  [`icon--size--${props.size}`]: true,
  [`icon--hover`]: props.hasAction,
}));
</script>

<script lang="ts">
import Arrow from './assets/arrow.svg?component';
import ExternalLink from './assets/external_link.svg?component';
import Failure from './assets/failure.svg?component';
import Mypage from './assets/mypage.svg?component';
import Telephone from './assets/telephone.svg?component';
export const ICON_COMPONENTS = {
  arrow: Arrow,
  externalLink: ExternalLink,
  failure: Failure,
  mypage: Mypage,
  telephone: Telephone,
} as const;

export const ICON_SIZES = ['12', '20', '24', '28', '32', '48', '64', '80'] as const;
</script>

<template>
  <component :is="ICON_COMPONENTS[name]" class="icon" :class="classes" />
</template>

<style lang="scss" scoped>
.icon {
  &--size--12 {
    width: 12px;
  }

  &--size--20 {
    width: 20px;
  }

  &--size--24 {
    width: 24px;
  }

  &--size--28 {
    width: 28px;
  }

  &--size--32 {
    width: 32px;
  }

  &--size--48 {
    width: 48px;
  }

  &--size--64 {
    width: 64px;
  }

  &--size--80 {
    width: 80px;
  }

  &--hover {
    cursor: pointer;
  }
}
</style>
