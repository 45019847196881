import { tryGetErrorData } from '@/views/error/utils';

import { MaintenanceConfig } from '../types';

import { createError } from '#imports';

const MAINTENANCE_ERROR = 'MAINTENANCE_ERROR';

export type MaintenanceErrorData = {
  type: typeof MAINTENANCE_ERROR;
} & Pick<MaintenanceConfig, 'description' | 'functionName' | 'layout'>;

/**
 * メンテンナンスエラーを生成する。
 * @param args - メンテナンスエラーの内容
 */
export const createMaintenanceError = (args: Omit<MaintenanceErrorData, 'type'>) =>
  createError({
    fatal: true,
    data: {
      type: MAINTENANCE_ERROR,
      ...args,
    },
  });

/**
 * 引数のエラー情報から、メンテンナンスエラーの情報の取得を試みる。
 * 取得できない場合はundefinedを返却する。
 * @param error - エラー情報
 */
export const tryGetMaintenanceErrorData = (error: unknown): MaintenanceErrorData | undefined =>
  tryGetErrorData(error, MAINTENANCE_ERROR);
