import { defineNuxtPlugin, useNuxtApp } from '#imports';

export default defineNuxtPlugin((nuxtApp) => {
  const { $error } = useNuxtApp();

  nuxtApp.vueApp.config.errorHandler = (err) => {
    if (err instanceof Error) {
      $error({
        event: 'client',
        errorName: err.name,
        errorMessage: err.message,
        stack: err.stack,
        cause: err.cause,
      });
    }
  };
});
