import { MAINTENANCE_PATTERN } from '@/functions/maintenance/types';

/**
 * 各画面のパスや画面名などを指定する。
 * metaフィールドは閉塞制御やタブに表示されるページタイトルなどに使用される。
 */
export const ROUTES = {
  // ルートパス
  ROOT: {
    path: '/',
    name: 'Root',
    meta: {
      viewId: '',
      viewName: '',
    },
  },
  // 簡易借入シミュレーション
  BORROW_SIMULATION: {
    path: '/borrow/simulation',
    name: 'borrow-simulation',
    meta: {
      viewId: 'BORROW_SIMULATION',
      viewName: 'お借入シミュレーション',
      patterns: [MAINTENANCE_PATTERN.LENS],
    },
  },
  // 簡易借入シミュレーション結果
  BORROW_SIMULATION_RESULT: {
    path: '/borrow/simulation/result',
    name: 'borrow-simulation-result',
    meta: {
      viewId: 'BORROW_SIMULATION_RESULT',
      viewName: 'お借入シミュレーション結果',
      patterns: [MAINTENANCE_PATTERN.LENS],
    },
  },
  // 返済計画表
  BORROW_SIMULATION_PLAN: {
    path: '/borrow/simulation/plan',
    name: 'borrow-simulation-plan',
    meta: {
      viewId: 'BORROW_SIMULATION_RESULT',
      viewName: 'お借入シミュレーション返済計画表',
      patterns: [MAINTENANCE_PATTERN.LENS],
    },
  },
  // システムエラー
  SYSTEM_ERROR: {
    path: '/system_error',
    name: 'SystemError',
    meta: {
      viewId: 'SYSTEM_ERROR',
    },
  },
  // 口座振替 オンライン口座登録の開始ページ
  BANK_TRANSFER_START: {
    path: '/bank_transfer/start',
    name: 'BANK_TRANSFER_START',
    meta: {
      viewId: 'BANK_TRANSFER_START',
      viewName: 'オンライン口座登録の開始',
      patterns: [MAINTENANCE_PATTERN.LENS, MAINTENANCE_PATTERN.DEBIT_GW],
    },
  },

  // 口座振替 オンライン口座登録の完了ページ
  BANK_TRANSFER_COMPLETE: {
    path: '/bank_transfer/complete',
    name: 'BANK_TRANSFER_COMPLETE',
    meta: {
      viewId: 'BANK_TRANSFER_COMPLETE',
      viewName: 'オンライン口座登録の完了',
      patterns: [MAINTENANCE_PATTERN.LENS],
    },
  },

  // 口座振替 オンライン口座登録の中断ページ
  BANK_TRANSFER_INTERRUPTION: {
    path: '/bank_transfer/interruption',
    name: 'BANK_TRANSFER_INTERRUPTION',
    meta: {
      viewId: 'BANK_TRANSFER_INTERRUPTION',
      viewName: 'オンライン口座登録の中断',
      patterns: [MAINTENANCE_PATTERN.LENS, MAINTENANCE_PATTERN.DEBIT_GW],
    },
  },

  // 口座振替 オンライン口座登録の失敗ページ
  BANK_TRANSFER_FAILURE: {
    path: '/bank_transfer/failure',
    name: 'BANK_TRANSFER_FAILURE',
    meta: {
      viewId: 'BANK_TRANSFER_FAILURE',
      viewName: 'オンライン口座登録の失敗',
      patterns: [MAINTENANCE_PATTERN.LENS, MAINTENANCE_PATTERN.DEBIT_GW],
    },
  },
} as const;
