<script setup lang="ts">
import UiIcon from '@/components/ui/icon/UiIcon.vue';

const BUTTON_VARIANT = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
} as const;
type ButtonVariant = (typeof BUTTON_VARIANT)[keyof typeof BUTTON_VARIANT];

const BUTTON_SIZE = {
  MD: 'md',
  SM: 'sm',
  XS: 'xs',
} as const;
type ButtonSize = (typeof BUTTON_SIZE)[keyof typeof BUTTON_SIZE];

withDefaults(
  defineProps<{
    variant?: ButtonVariant;
    size?: ButtonSize;
    disabled?: boolean;
  }>(),
  {
    variant: 'primary',
    size: 'md',
    disabled: false,
  }
);
</script>

<template>
  <button v-bind="$attrs" :disabled="disabled" class="button" :class="[variant, size]">
    <slot />
    <UiIcon v-if="BUTTON_VARIANT.TERTIARY === variant" name="arrow" size="12" class="img-arrow" />
  </button>
</template>

<style lang="scss" scoped>
.button {
  width: 100%;
  max-width: 328px;
  border-radius: 100px;
  text-align: center;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
  &.primary {
    background: var(--vt-brand-primary-color);
    color: var(--vt-bg-primary-color);
    font-weight: bold;
    height: 50px;
    &:active {
      background: var(--vt-c-deep-blush);
    }
    &:disabled {
      background: var(--vt-c-blue-haze);
      color: var(--vt-c-white);
    }
  }
  &.secondary {
    color: var(--vt-brand-primary-color);
    background: var(--vt-bg-primary-color);
    border: 1px solid var(--vt-brand-primary-color);
    height: 50px;
    &:active {
      color: var(--vt-c-deep-blush);
      border-color: var(--vt-c-deep-blush);
    }
    &:disabled {
      background: var(--vt-c-blue-haze);
      color: var(--vt-c-white);
      border: none;
    }
  }
  &.tertiary {
    color: var(--vt-brand-primary-color);
    &:active {
      color: var(--vt-c-deep-blush);
    }
    &:disabled {
      color: var(--vt-c-blue-haze);
    }
  }
  &.md {
    font-size: 1.4rem;
  }
  &.sm {
    height: 38px;
    font-size: 1.2rem;
  }
  &.xs {
    height: 28px;
    font-size: 1.2rem;
  }

  .img-arrow {
    margin-left: 5px;

    &:deep(path) {
      stroke: var(--vt-c-monza);
    }
  }

  &:disabled {
    .img-arrow {
      &:deep(path) {
        stroke: var(--vt-c-blue-haze);
      }
    }
  }
}
</style>
