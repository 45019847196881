<script setup lang="ts">
import { UiButton, UiIcon } from '@/components/ui';

// 問い合わせ電話をかける
const handleCallNumber = () => {
  window.location.href = 'tel:0120-818-360';
};
</script>
<template>
  <div class="ui-contact">
    <div class="title">
      <p class="content-center">dスマホローンセンター</p>
      <div class="content-dial">
        <p>お問い合わせ</p>
        <p>お客さま相談ダイヤル</p>
      </div>
    </div>
    <div class="body">
      <div class="content-tel">
        <UiIcon name="telephone" size="24" class="content-tel-icon" />
        <p class="content-tel-no">0120-818-360</p>
        <span class="content-tel-text">(無料)</span>
      </div>
      <p class="content-time">受付時間：10:00～20:00 年中無休</p>
      <UiButton class="content-button" @click="handleCallNumber">お電話はこちらから</UiButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import './assets/scss/mediaQuery.scss';
.ui-contact {
  width: 100%;
  background-color: var(--vt-bg-secondary-color);
  padding: 27px 15px 40px 15px;
  text-align: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;

  @include pc {
    flex-direction: row;
    justify-content: center;
    gap: 30px;
  }
}

.title {
  text-align: center;
  @include pc {
    text-align: left;
  }
}

.content-center {
  font-size: 1.6rem;
  font-weight: bold;
}
.content-dial {
  margin-top: 3px;
  font-size: 1.8rem;
  font-weight: bold;
}
.content-tel {
  display: flex;
  justify-content: center;
  margin-top: 4px;
  align-items: flex-end;
}
.content-tel-icon {
  margin-bottom: 6px;
}
.content-tel-no {
  font-size: 2.8rem;
  font-weight: bold;
  color: var(--vt-brand-primary-color);
  margin-left: 5px;
}

.content-tel-text {
  font-size: 1.4rem;
  color: var(--vt-text-secondary-color);
  margin-left: 5px;
  margin-bottom: 6px;
}
.content-time {
  margin-top: 9px;
  font-size: 1.4rem;
  color: var(--vt-text-secondary-color);

  @include pc {
    margin-top: 0;
  }
}
.content-button {
  margin-top: 17px;

  @include pc {
    display: none;
  }
}
</style>
