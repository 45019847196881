<script setup lang="ts">
import { computed } from 'vue';

import { UiButton, UiGlobalHeader } from '@/components/ui';

import { MAINTENANCE_LAYOUTS } from '../enums';

import { MaintenanceErrorData } from './maintenanceError';
import { SimpleMaintenanceErrorContent, WithFunctionNameMaintenanceErrorContent } from './layouts';
import { navigateTo } from '#app';

const props = defineProps<{ errorData: MaintenanceErrorData }>();

const layoutRef = computed(() => props.errorData.layout);

const handleClickButton = async () => {
  await navigateTo('https://loan.docomo.ne.jp', { external: true });
};
</script>

<template>
  <div class="page">
    <UiGlobalHeader title="システムメンテナンス" />
    <main class="body">
      <div class="content">
        <div class="heading">
          <div class="messages">
            <SimpleMaintenanceErrorContent v-if="layoutRef === MAINTENANCE_LAYOUTS.SIMPLE" :error-data="errorData" />
            <WithFunctionNameMaintenanceErrorContent
              v-else-if="MAINTENANCE_LAYOUTS.WITH_FUNCTION_NAME"
              :error-data="errorData"
            />
          </div>
        </div>
        <div class="footer-button">
          <UiButton @click="handleClickButton">ホームへ</UiButton>
        </div>
      </div>
    </main>
  </div>
</template>

<style lang="scss" scoped>
:deep(.header) {
  background-color: var(--vt-brand-primary-color);
  box-shadow: none;
}
.page {
  height: 100dvh;
}
.body {
  height: calc(100% - 44px);
}
.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 72px;
}
.heading {
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.messages {
  margin-top: 16px;
  white-space: pre-wrap;
  font-size: 1.4rem;
}
.footer-button {
  width: 100%;
  text-align: center;
}
</style>
