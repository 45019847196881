import validate from "/builds/zone/dev/web/zone-service-top/node_modules/nuxt/dist/pages/runtime/validate.js";
import _10_45maintenance_45middleware_45global from "/builds/zone/dev/web/zone-service-top/middleware/10.maintenance-middleware.global.ts";
import _20_45logging_45middleware_45global from "/builds/zone/dev/web/zone-service-top/middleware/20.logging-middleware.global.ts";
import _40_45gtm_45view_45event_45global from "/builds/zone/dev/web/zone-service-top/middleware/40.gtm-view-event.global.ts";
import manifest_45route_45rule from "/builds/zone/dev/web/zone-service-top/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _10_45maintenance_45middleware_45global,
  _20_45logging_45middleware_45global,
  _40_45gtm_45view_45event_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}