import { BusinessErrorCode } from '../';

type ClientBusinessErrorProps = {
  errorCode: BusinessErrorCode;
};

/**
 * クライアント側でスローする業務エラー
 */
export class ClientBusinessError extends Error {
  static {
    this.prototype.name = 'ClientBusinessError';
  }

  errorCode: BusinessErrorCode;

  /**
   *  コンストラクタ
   * @param message - エラーメッセージ
   * @param options - 継承元のErrorに渡したい情報
   */
  constructor(message = '', options: ClientBusinessErrorProps & ErrorOptions) {
    // NOTE: https://www.wantedly.com/companies/wantedly/post_articles/492456
    const { errorCode, ...rest } = options;
    // causeがあるときはErrorに渡される
    super(message, rest);
    this.errorCode = errorCode;
  }
}
