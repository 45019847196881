<script setup lang="ts">
import { tryGetBusinessError, BusinessErrorPage } from '@/functions/error/views';
import { SystemErrorPage } from '@/views';
import { tryGetMaintenanceErrorData, MaintenanceErrorPage } from '@/functions/maintenance/views';
import { NotFoundPage } from '@/functions/error/views';

import { navigateTo } from '#app';
import { NuxtApp, useError } from '#app';
import { computed } from '#imports';

const notFoundError = useError();
const props = defineProps<{ error: NuxtApp['payload']['error'] }>();
const handleClearError = async () => {
  await navigateTo('/');
};

/**
 * メンテナンスエラーの場合、メンテナンスエラー情報を返却する。
 */
const maintenanceErrorDataRef = computed(() => tryGetMaintenanceErrorData(props.error));

const businessErrorDataRef = computed(() => tryGetBusinessError(props.error));

document.querySelector('body')?.setAttribute('style', 'padding-top: 0;');
</script>

<template>
  <template v-if="maintenanceErrorDataRef">
    <!-- メンテナンスエラー -->
    <MaintenanceErrorPage :error-data="maintenanceErrorDataRef" />
  </template>
  <!-- 業務エラー -->
  <template v-else-if="businessErrorDataRef">
    <BusinessErrorPage :error-data="businessErrorDataRef" />
  </template>
  <!-- 404エラー -->
  <template v-else-if="notFoundError?.statusCode === 404">
    <NotFoundPage />
  </template>
  <!-- 想定外のシステムエラー -->
  <template v-else>
    <SystemErrorPage @clear-error="handleClearError" />
  </template>
</template>
