import revive_payload_client_4sVQNw7RlN from "/builds/zone/dev/web/zone-service-top/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/zone/dev/web/zone-service-top/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/zone/dev/web/zone-service-top/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/zone/dev/web/zone-service-top/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/zone/dev/web/zone-service-top/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/builds/zone/dev/web/zone-service-top/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/zone/dev/web/zone-service-top/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/zone/dev/web/zone-service-top/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/builds/zone/dev/web/zone-service-top/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/builds/zone/dev/web/zone-service-top/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_datadogLog_client_Jou2ko4mVS from "/builds/zone/dev/web/zone-service-top/plugins/01.datadogLog.client.ts";
import _02_datadogRum_client_eU1AX1TfPz from "/builds/zone/dev/web/zone-service-top/plugins/02.datadogRum.client.ts";
import _03_logger_VDTDyHfSpH from "/builds/zone/dev/web/zone-service-top/plugins/03.logger.ts";
import error_ldt3PQauZ0 from "/builds/zone/dev/web/zone-service-top/plugins/error.ts";
import format_wexqKEqeoL from "/builds/zone/dev/web/zone-service-top/plugins/format.ts";
import vue_query_wrmMkNpEpe from "/builds/zone/dev/web/zone-service-top/plugins/vue-query.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  chunk_reload_client_UciE0i6zes,
  _01_datadogLog_client_Jou2ko4mVS,
  _02_datadogRum_client_eU1AX1TfPz,
  _03_logger_VDTDyHfSpH,
  error_ldt3PQauZ0,
  format_wexqKEqeoL,
  vue_query_wrmMkNpEpe
]