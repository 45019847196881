<script setup lang="ts"></script>

<template>
  <footer class="footer">
    <!-- sp menu -->
    <nav class="footer_sp">
      <ul class="menu_list_sp">
        <li class="menu_item_sp">
          <a href="/" class="menu_item_sp_inr bdr_top arrow_icon">
            <div class="menu_item_sp_icon">
              <img src="/img/menu_icon_01.svg" alt="トップページ" width="28" height="28" />
            </div>
            <p class="menu_sp_text01">トップページ</p>
          </a>
        </li>
        <li class="menu_item_sp">
          <a href="/campaign/" class="menu_item_sp_inr arrow_icon">
            <div class="menu_item_sp_icon">
              <img src="/img/menu_icon_02.svg" alt="キャンペーン" width="28" height="28" />
            </div>
            <p class="menu_sp_text01">キャンペーン</p>
          </a>
        </li>
        <li class="menu_item_sp">
          <input id="footer_01" type="checkbox" class="menu_item_sp_check acc_head" />
          <label for="footer_01" class="menu_item_sp_inr acc_icon">
            <span class="menu_item_sp_icon"
              ><img src="/img/menu_icon_03.svg" alt="はじめてのdスマホローン" width="28" height="28"
            /></span>
            <span class="menu_sp_text01">はじめてのdスマホローン</span>
          </label>
          <ul class="menu_sp_subList acc_body">
            <li class="menu_item_sp"><a href="/about/" class="menu_item_sp_inr arrow_icon">dスマホローンとは</a></li>
            <li class="menu_item_sp"><a href="/about/summary/" class="menu_item_sp_inr arrow_icon">商品概要</a></li>
            <li class="menu_item_sp">
              <a href="/about/flow/" class="menu_item_sp_inr arrow_icon">お申込みからご返済までの流れ</a>
            </li>
            <li class="menu_item_sp">
              <a href="/about/app/" class="menu_item_sp_inr arrow_icon">便利なdスマホローンアプリ</a>
            </li>
            <li class="menu_item_sp">
              <a href="/article/" class="menu_item_sp_inr arrow_icon">dスマホローンのお役立ち記事</a>
            </li>
          </ul>
        </li>
        <li class="menu_item_sp">
          <input id="footer_02" type="checkbox" class="menu_item_sp_check acc_head" />
          <label for="footer_02" class="menu_item_sp_inr acc_icon">
            <span class="menu_item_sp_icon"
              ><img src="/img/menu_icon_04.svg" alt="お申込みをする" width="28" height="28"
            /></span>
            <span class="menu_sp_text01">お申込みをする</span>
          </label>
          <ul class="menu_sp_subList acc_body">
            <li class="menu_item_sp"><a href="/apply/" class="menu_item_sp_inr arrow_icon">お申込みについて</a></li>
            <li class="menu_item_sp">
              <a href="/apply/docs/" class="menu_item_sp_inr arrow_icon">お申込みに必要な書類について</a>
            </li>
            <li class="menu_item_sp">
              <a href="/apply/certificate/" class="menu_item_sp_inr arrow_icon">収入証明書類について</a>
            </li>
            <li class="menu_item_sp">
              <a href="/apply/identification/" class="menu_item_sp_inr arrow_icon">本人確認方法について</a>
            </li>
            <li class="menu_item_sp">
              <a href="/apply/banklist/" class="menu_item_sp_inr arrow_icon">ご利用可能な金融機関と振込実施時間</a>
            </li>
            <li class="menu_item_sp">
              <a href="/apply/1second/" class="menu_item_sp_inr arrow_icon">お借入れ可能？簡単1秒診断</a>
            </li>
          </ul>
        </li>
        <li class="menu_item_sp">
          <input id="footer_03" type="checkbox" class="menu_item_sp_check acc_head" />
          <label for="footer_03" class="menu_item_sp_inr acc_icon">
            <span class="menu_item_sp_icon"
              ><img src="/img/menu_icon_05.svg" alt="お借入れをする" width="28" height="28"
            /></span>
            <span class="menu_sp_text01">お借入れをする</span>
          </label>
          <ul class="menu_sp_subList acc_body">
            <li class="menu_item_sp"><a href="/borrow/" class="menu_item_sp_inr arrow_icon">お借入れについて</a></li>
            <li class="menu_item_sp">
              <a href="/borrow/d_payment/" class="menu_item_sp_inr arrow_icon">d払い残高にお借入れ</a>
            </li>
            <li class="menu_item_sp">
              <a href="/borrow/bank/" class="menu_item_sp_inr arrow_icon">ご登録の金融機関口座にお借入れ</a>
            </li>
            <li class="menu_item_sp">
              <a href="/borrow/rate/" class="menu_item_sp_inr arrow_icon">お借入利率・お利息計算方法</a>
            </li>
            <li class="menu_item_sp">
              <a href="/borrow/limit/" class="menu_item_sp_inr arrow_icon">お借入限度額・増額方法</a>
            </li>
            <li class="menu_item_sp"><a href="/borrow/benefit/" class="menu_item_sp_inr arrow_icon">優遇金利</a></li>
          </ul>
        </li>
        <li class="menu_item_sp">
          <input id="footer_04" type="checkbox" class="menu_item_sp_check acc_head" />
          <label for="footer_04" class="menu_item_sp_inr acc_icon">
            <span class="menu_item_sp_icon"
              ><img src="/img/menu_icon_06.svg" alt="ご返済をする" width="28" height="28"
            /></span>
            <span class="menu_sp_text01">ご返済をする</span>
          </label>
          <ul class="menu_sp_subList acc_body">
            <li class="menu_item_sp"><a href="/repay/" class="menu_item_sp_inr arrow_icon">ご返済について</a></li>
            <li class="menu_item_sp">
              <a href="/repay/d_payment/" class="menu_item_sp_inr arrow_icon">d払い残高からの追加返済</a>
            </li>
            <li class="menu_item_sp">
              <a href="/repay/bank/" class="menu_item_sp_inr arrow_icon">指定口座へのお振込みでの追加返済</a>
            </li>
            <li class="menu_item_sp">
              <a href="/repay/term/" class="menu_item_sp_inr arrow_icon">ご返済期日について</a>
            </li>
            <li class="menu_item_sp">
              <a href="/repay/repayment/" class="menu_item_sp_inr arrow_icon">ご返済金額について</a>
            </li>
            <li class="menu_item_sp">
              <a href="/repay/bullet/" class="menu_item_sp_inr arrow_icon">一括返済について</a>
            </li>
            <li class="menu_item_sp">
              <a href="/borrow/simulation/" class="menu_item_sp_inr arrow_icon">ご返済シミュレーション</a>
            </li>
          </ul>
        </li>
        <li class="menu_item_sp">
          <input id="footer_05" type="checkbox" class="menu_item_sp_check acc_head" />
          <label for="footer_05" class="menu_item_sp_inr acc_icon">
            <span class="menu_item_sp_icon"
              ><img src="/img/menu_icon_07.svg" alt="お知らせ" width="28" height="28"
            /></span>
            <span class="menu_sp_text01">お知らせ</span>
          </label>
          <ul class="menu_sp_subList acc_body">
            <li class="menu_item_sp"><a href="/info/" class="menu_item_sp_inr arrow_icon">お知らせ一覧</a></li>
            <li class="menu_item_sp">
              <a href="/info/20230901_01/" class="menu_item_sp_inr arrow_icon">お客さま情報のご確認のお願い</a>
            </li>
          </ul>
        </li>
        <li class="menu_item_sp">
          <input id="footer_06" type="checkbox" class="menu_item_sp_check acc_head" />
          <label for="footer_06" class="menu_item_sp_inr acc_icon">
            <span class="menu_item_sp_icon"
              ><img src="/img/menu_icon_09.svg" alt="お問い合わせ" width="28" height="28"
            /></span>
            <span class="menu_sp_text01">お問い合わせ</span>
          </label>
          <ul class="menu_sp_subList acc_body">
            <li class="menu_item_sp">
              <a href="/inquiry/" class="menu_item_sp_inr arrow_icon">お問い合わせについて</a>
            </li>
            <li class="menu_item_sp">
              <a href="/inquiry/chat/" class="menu_item_sp_inr arrow_icon">dスマホローンチャットサービスについて</a>
            </li>
          </ul>
        </li>
        <li class="menu_item_sp">
          <a href="/faq/" class="menu_item_sp_inr arrow_icon">
            <div class="menu_item_sp_icon">
              <img src="/img/menu_icon_08.svg" alt="よくあるご質問" width="22" height="22" />
            </div>
            <p class="menu_sp_text01"><span>よくある</span><span>ご質問</span></p>
          </a>
        </li>
      </ul>
    </nav>
    <!-- sp menu -->

    <!-- pc menu -->
    <nav class="footer_link footer_pc">
      <ul class="footer_link_inr">
        <li class="footer_list">
          <p class="footer_link_top">
            <a href="/"><span class="arrow_icon">トップページ</span></a>
          </p>
        </li>
        <li class="footer_list">
          <p class="footer_link_top">
            <a href="/campaign/"><span class="arrow_icon">キャンペーン</span></a>
          </p>
        </li>
        <li class="footer_list">
          <ul class="footer_list_inr">
            <li class="footer_link_top">はじめてのdスマホローン</li>
            <li>
              <a href="/about/"><span class="arrow_icon">dスマホローンとは</span></a>
            </li>
            <li>
              <a href="/about/summary/"><span class="arrow_icon">商品概要</span></a>
            </li>
            <li>
              <a href="/about/flow/"><span class="arrow_icon">お申込みからご返済までの流れ</span></a>
            </li>
            <li>
              <a href="/about/app/"><span class="arrow_icon">便利なdスマホローンアプリ</span></a>
            </li>
            <li>
              <a href="/article/"><span class="arrow_icon">dスマホローンのお役立ち記事</span></a>
            </li>
          </ul>
        </li>
        <li class="footer_list">
          <ul class="footer_list_inr">
            <li class="footer_link_top">お申込みをする</li>
            <li>
              <a href="/apply/"><span class="arrow_icon">お申込みについて</span></a>
            </li>
            <li>
              <a href="/apply/docs/"><span class="arrow_icon">お申込みに必要な書類について</span></a>
            </li>
            <li>
              <a href="/apply/certificate/"><span class="arrow_icon">収入証明書類について</span></a>
            </li>
            <li>
              <a href="/apply/identification/"><span class="arrow_icon">本人確認方法について</span></a>
            </li>
            <li>
              <a href="/apply/banklist/"
                ><span class="arrow_icon"
                  ><span class="ask">ご利用可能な金融機関と</span><span class="ask">振込実施時間</span></span
                ></a
              >
            </li>
            <li>
              <a href="/apply/1second/"><span class="arrow_icon">お借入れ可能？簡単1秒診断</span></a>
            </li>
          </ul>
        </li>
        <li class="footer_list">
          <ul class="footer_list_inr">
            <li class="footer_link_top">お借入れをする</li>
            <li>
              <a href="/borrow/"><span class="arrow_icon">お借入れについて</span></a>
            </li>
            <li>
              <a href="/borrow/d_payment/"><span class="arrow_icon">d払い残高にお借入れ</span></a>
            </li>
            <li>
              <a href="/borrow/bank/"><span class="arrow_icon">ご登録の金融機関口座にお借入れ</span></a>
            </li>
            <li>
              <a href="/borrow/rate/"><span class="arrow_icon">お借入利率・お利息計算方法</span></a>
            </li>
            <li>
              <a href="/borrow/limit/"><span class="arrow_icon">お借入限度額・増額方法</span></a>
            </li>
            <li>
              <a href="/borrow/benefit/"><span class="arrow_icon">優遇金利</span></a>
            </li>
          </ul>
        </li>
        <li class="footer_list">
          <ul class="footer_list_inr">
            <li class="footer_link_top">ご返済をする</li>
            <li>
              <a href="/repay/"><span class="arrow_icon">ご返済について</span></a>
            </li>
            <li>
              <a href="/repay/d_payment/"><span class="arrow_icon">d払い残高からの追加返済</span></a>
            </li>
            <li>
              <a href="/repay/bank/"
                ><span class="arrow_icon"
                  ><span class="ask">指定口座へのお振込みでの</span><span class="ask">追加返済</span></span
                ></a
              >
            </li>
            <li>
              <a href="/repay/term/"><span class="arrow_icon">ご返済期日について</span></a>
            </li>
            <li>
              <a href="/repay/repayment/"><span class="arrow_icon">ご返済金額について</span></a>
            </li>
            <li>
              <a href="/repay/bullet/"><span class="arrow_icon">一括返済について</span></a>
            </li>
            <li>
              <a href="/borrow/simulation/"><span class="arrow_icon">ご返済シミュレーション</span></a>
            </li>
          </ul>
        </li>
        <li class="footer_list">
          <ul class="footer_list_inr">
            <li class="footer_link_top">お知らせ</li>
            <li>
              <a href="/info/"><span class="arrow_icon">お知らせ一覧</span></a>
            </li>
            <li>
              <a href="/info/20230901_01/"><span class="arrow_icon">お客さま情報のご確認のお願い</span></a>
            </li>
          </ul>
        </li>
        <li class="footer_list">
          <ul class="footer_list_inr">
            <li class="footer_link_top">お問い合わせ</li>
            <li>
              <a href="/inquiry/"><span class="arrow_icon">お問い合わせについて</span></a>
            </li>
            <li>
              <a href="/inquiry/chat/"><span class="arrow_icon">dスマホローンチャットサービスについて</span></a>
            </li>
          </ul>
        </li>
        <li class="footer_list">
          <p class="footer_link_top">
            <a href="/faq/"><span class="arrow_icon">よくあるご質問</span></a>
          </p>
        </li>
      </ul>
    </nav>
    <!-- pc menu -->
    <div class="footer_item">
      <div class="footer_item_link">
        <ul class="footer_item_link_inr">
          <li><a class="arrow_icon" href="/termslist/">各種規約</a></li>
          <li><a class="arrow_icon" href="/terms/">サイトご利用にあたって</a></li>
          <li>
            <a class="outLink" href="https://www.nttdocomo.co.jp/corporate/" target="_blank" rel="noopener"
              >運営会社概要</a
            >
          </li>
          <li>
            <a class="outLink" href="https://www.nttdocomo.co.jp/utility/privacy/" target="_blank" rel="noopener"
              >プライバシーポリシー</a
            >
          </li>
          <li>
            <a
              class="outLink"
              href="https://www.nttdocomo.co.jp/corporate/about/group_ethic/"
              target="_blank"
              rel="noopener"
              >NTTドコモグループ倫理方針</a
            >
          </li>
        </ul>
      </div>
      <!-- /.footer_item_link -->
      <div class="footer_logo_link">
        <ul class="footer_logo_link_inr">
          <li class="logo_link_item">
            <a href="https://d-card.jp/st/" target="_blank" rel="noopener"
              ><img src="/img/footer_img_01.svg" alt="dCARD" width="77" height="22"
            /></a>
          </li>
          <li class="logo_link_item">
            <a href="https://service.smt.docomo.ne.jp/keitai_payment/" target="_blank" rel="noopener"
              ><img src="/img/footer_img_02.svg" alt="d払い" width="55" height="22"
            /></a>
          </li>
          <li class="logo_link_item">
            <a
              href="https://dsmartbank.docomo.ne.jp/?utm_source=d_sploan&utm_medium=owned&utm_campaign=dsmartbank_202212_top"
              target="_blank"
              rel="noopener"
              ><img src="/img/footer_img_04.svg" alt="dスマートバンク" width="86" height="25"
            /></a>
          </li>
        </ul>
      </div>
      <!-- /.footer_logo_link -->
      <div class="footer_warning">
        <div class="footer_warning_inr">
          <p class="footer_warning_tll">
            <span class="ask">お借入れの条件をご確認の上、</span><span class="ask">借りすぎに注意しましょう。</span>
          </p>
          <div class="footer_warning_clm">
            <div class="footer_box">
              <div class="footer_icon_tll">
                <div class="img">
                  <img src="/img/footer_icon_01.svg" alt="日本貸金業協会" width="38" height="38" />
                </div>
                <div class="tll_area">
                  <p class="footer_box_tll_lead">お借入れ・ご返済などのご相談は</p>
                  <p class="footer_box_tll">日本貸金業協会</p>
                </div>
              </div>
              <p>
                <a class="outLink" href="https://www.j-fsa.or.jp/" target="_blank" rel="noopener"
                  >日本貸金業協会のWebサイトへ</a
                >
              </p>
              <p class="footer_box_tll mt20">指定紛争解決機関</p>
              <dl class="foter_data mt20">
                <div class="foter_data_item">
                  <dt>名称</dt>
                  <dd>
                    <span class="ask">日本貸金業協会&#12288;</span><span class="ask">貸金業相談・紛争解決センター</span>
                  </dd>
                </div>
                <div class="foter_data_item">
                  <dt>所在地</dt>
                  <dd>
                    <span class="ask">&#12306;108-0074&#12288;</span><span class="ask">東京都港区高輪3-19-15</span>
                  </dd>
                </div>
                <div class="foter_data_item">
                  <dt>電話番号</dt>
                  <dd><a href="tel:0357393861">03-5739-3861</a></dd>
                </div>
                <div class="foter_data_item">
                  <dt>受付時間</dt>
                  <dd>9:00～17:00（土・日・祝休日 12/29～1/4を除く）</dd>
                </div>
              </dl>
            </div>
            <div>
              <div class="footer_box">
                <p class="footer_box_tll">株式会社NTTドコモ</p>
                <dl class="foter_data">
                  <div class="foter_data_item">
                    <dt>電話番号</dt>
                    <dd>
                      03-5156-1111<br /><a class="footer_arrow_icon footer_arrow_icon02" href="/inquiry/"
                        >お問い合わせはこちら</a
                      >
                    </dd>
                  </div>
                  <div class="foter_data_item">
                    <dt>登録番号</dt>
                    <dd><span class="ask">関東財務局長（7）</span><span class="ask">第01421号</span></dd>
                  </div>
                  <div class="foter_data_item">
                    <dt>協会員番号</dt>
                    <dd><span class="ask">日本貸金業協会会員 </span><span class="ask">第003506号</span></dd>
                  </div>
                </dl>
              </div>
              <div class="footer_box">
                <p class="footer_box_tll">加盟信用情報機関</p>
                <p>
                  <a class="outLink" href="https://www.jicc.co.jp/" target="_blank" rel="noopener"
                    >株式会社日本信用情報機構（JICC）</a
                  >
                </p>
                <p class="mt10">
                  <a class="outLink" href="https://www.cic.co.jp/" target="_blank" rel="noopener"
                    >株式会社シー・アイ・シー（CIC）</a
                  >
                </p>
              </div>
            </div>
          </div>
          <!-- /.footer_warning_clm -->
        </div>
      </div>
      <!-- /.footer_warning -->
      <div class="footer_copy">
        <p class="copy_txt"><small>©NTT DOCOMO, INC. All Rights Reserved.</small></p>
      </div>
    </div>
    <!-- /.footer_item -->
  </footer>
</template>
