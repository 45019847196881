import { useRuntimeConfig } from '#imports';

/**
 * 現在の環境のURLをもとに、ドメインにsp.を付与してZONEにアクセスするURL返却する関数
 * @returns - sp.ドメインのURL
 */
export const convertUrl = () => {
  let baseURL = useRuntimeConfig().public.apiBaseUrl;

  // ローカルホストが取得される場合は、不正なURLでエラーになってしまうのでdevelopのURLを代入する
  if (baseURL.includes('localhost:3000')) {
    baseURL = 'https://dev.zone.docomo.ne.jp/';
  }

  const urlObj = new URL(baseURL);
  const hostnameParts = urlObj.hostname.split('.');

  hostnameParts[0] = 'sp.' + hostnameParts[0];

  urlObj.hostname = hostnameParts.join('.');

  return urlObj.toString();
};
