const API_PREFIX = '/api';

export const API_URL = {
  // 閉塞制御設定参照
  GET_MAINTENANCE: `${API_PREFIX}/maintenance`,

  // ロギングAPI
  POST_LOG: `${API_PREFIX}/log`,

  // 現在のシステム時刻を取得(BFFサーバ)
  GET_CURRENT_SYSTEM_DATE_TIME: `${API_PREFIX}/time/current_time`,

  // 口振Redis取得API
  GET_INFO_LIST: `${API_PREFIX}/infoList`,

  // 口振Redis取得API
  GET_BANK_TRANSFER: `${API_PREFIX}/bank_transfer`,

  // 端末情報更新API
  POST_BANK_TRANSFER_USER_AGENT: `${API_PREFIX}/bank_transfer`,

  // 口振Redis削除API
  GET_BANK_TRANSFER_RESET: `${API_PREFIX}/bank_transfer/reset`,
  // 簡単1秒診断入力情報保存
  PUT_ONE_SECOND_INFO_SAVE: `${API_PREFIX}/oneSecond/info`,
  // host取得
  GET_HOST: `${API_PREFIX}/host`,

  // キャンペーン一覧取得
  GET_CAMPAIGN_LIST: `${API_PREFIX}/common/campaign/list`,

  // キャンペーン情報取得
  GET_CAMPAIGN_INFO: `${API_PREFIX}/common/campaign/info`,

  // 借入シュミレーション情報 セッション登録
  POST_SESSION_REPAYMENT_SIMULATION: `${API_PREFIX}/borrow/session_repayment_simulation`,

  // 簡易借入シミュレーションAPI
  GET_SIMULATION_SIMPLE_BORROWINGS: `${API_PREFIX}/simulation/simple_borrowings`,

  // カテゴリ別FAQ一覧取得
  GET_FAQ_LIST_BY_CATEGORY: `${API_PREFIX}/faq/list_by_category`,

  // FAQ詳細取得
  GET_FAQ_DETAIL: `${API_PREFIX}/faq/detail`,

  // 規約取得
  GET_TERMS: `${API_PREFIX}/terms`,
};
