import { ErrorLogData, NormalLogData } from '@/functions/log';
import { API_URL, isClient } from '@/utils';

import { LogClient } from './LogClient';

import { useRuntimeConfig } from '#imports';

export const provide = {
  // NOTE: アカウント識別子はサーバ側で付与するため、Omitする
  info(data: Omit<NormalLogData, 'accountId' | 'sessionId'>) {
    const clientLog = isClient() ? useRuntimeConfig().public.clientLog : process.env.NUXT_PUBLIC_CLIENT_LOG !== 'false';
    if (process.dev) console.info(data);
    else if (clientLog) new LogClient().post(`${API_URL.POST_LOG}?type=info`, data);
  },
  debug(data: Omit<NormalLogData, 'accountId' | 'sessionId'>) {
    const clientLog = isClient() ? useRuntimeConfig().public.clientLog : process.env.NUXT_PUBLIC_CLIENT_LOG !== 'false';
    if (process.dev) console.debug(data);
    else if (clientLog) new LogClient().post(`${API_URL.POST_LOG}?type=debug`, data);
  },
  warn(data: Omit<NormalLogData, 'accountId' | 'sessionId'>) {
    const clientLog = isClient() ? useRuntimeConfig().public.clientLog : process.env.NUXT_PUBLIC_CLIENT_LOG !== 'false';
    if (process.dev) console.warn(data);
    else if (clientLog) new LogClient().post(`${API_URL.POST_LOG}?type=warn`, data);
  },
  error(data: Omit<ErrorLogData, 'accountId' | 'sessionId'>) {
    const clientLog = isClient() ? useRuntimeConfig().public.clientLog : process.env.NUXT_PUBLIC_CLIENT_LOG !== 'false';
    if (process.dev) console.error(data);
    else if (clientLog) new LogClient().post(API_URL.POST_LOG, data);
  },
};
