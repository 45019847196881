<script setup lang="ts">
import { computed } from 'vue';

import { BusinessErrorData } from './businessError';
import { LAYOUTS, BUSINESS_ERROR_MAP } from './enums';
import { SimpleErrorPage, WithContactErrorPage } from './layouts';

const props = defineProps<{ errorData: BusinessErrorData }>();

const errorCodeRef = computed(() => props.errorData.errorCode);

const errorPropsRef = computed(() => BUSINESS_ERROR_MAP[errorCodeRef.value]);
</script>

<template>
  <template v-if="errorPropsRef">
    <template v-if="errorPropsRef.layout === LAYOUTS.WITH_CONTACT">
      <WithContactErrorPage v-bind="errorPropsRef" :error-code="errorCodeRef" />
    </template>
    <template v-else>
      <SimpleErrorPage v-bind="errorPropsRef" />
    </template>
  </template>
</template>

<style scoped lang="scss"></style>
