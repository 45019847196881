import { defineNuxtPlugin } from '#imports';

export const provide = {
  formatPhone(value: { areaCode: string; localAreaCode: string; subscriberNumber: string }) {
    const { areaCode, localAreaCode, subscriberNumber } = value;
    return `${areaCode}-${localAreaCode}-${subscriberNumber}`;
  },
  formatPostCode(value: string) {
    return `${value.substring(0, 3)}-${value.substring(3, 7)}`;
  },
  formatBankAccount(value: { accountNumber: string; omissionNumberOfDigits: number }) {
    const ABBREVIATION = '*';

    const { accountNumber, omissionNumberOfDigits } = value;

    const diff = accountNumber.length - omissionNumberOfDigits;
    return `${ABBREVIATION.repeat(diff)}${accountNumber.substring(diff)}`;
  },
};

export default defineNuxtPlugin((_nuxtApp) => {
  return {
    provide,
  };
});
