import { MaintenanceLayouts } from './enums';

export const MAINTENANCE_PATTERN = {
  ALADIN: 'ALADIN',
  MOBILLS: 'MOBILLS',
  DEBIT_GW: 'DEBIT_GW',
  LENS: 'LENS',
} as const;

export type MaintenancePattern = (typeof MAINTENANCE_PATTERN)[keyof typeof MAINTENANCE_PATTERN];

export type MaintenanceConfig = {
  pattern: MaintenancePattern;
  enabled: boolean;
  // AEMはデフォルトでISO8791で返すのでその想定
  start: string;
  end: string;
  description: string;
  functionName: string;
  layout: MaintenanceLayouts;
};

export type GetMaintenanceResponse = MaintenanceConfig[];
