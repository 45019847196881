import { ROUTES } from '@/enums';

import { BUSINESS_ERROR_CODE, BusinessErrorCode } from '../../';

import { LAYOUTS } from './';

export type BusinessErrorProps = {
  message: string;
  errorCode?: BusinessErrorCode;
  title?: string;
  layout?: string;
  backToText?: string;
  backTo?: string;
};

export const BUSINESS_ERROR_MAP: {
  [key in BusinessErrorCode]?: BusinessErrorProps;
} = {
  [BUSINESS_ERROR_CODE.L0013]: {
    message: 'お借入希望額は1万円～300万円の範囲内で入力してください。（L0013）',
  },
  [BUSINESS_ERROR_CODE.L0020]: {
    message: '1,000,000円以上でシミュレーションを行う場合、借入利率は15.0％以下でご入力ください。',
  },
} as const;
