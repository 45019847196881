import { tryGetErrorData } from '@/views/error/utils';

import { BusinessErrorCode } from '../enums';

import { createError } from '#imports';

const BUSINESS_ERROR = 'BUSINESS_ERROR';

/**
 * ClientBusinessErrorからH3Errorに変換された後のエラー
 */
export type BusinessErrorData = {
  type: typeof BUSINESS_ERROR;
  errorCode: BusinessErrorCode;
};

/**
 * 業務エラーを生成する
 * @param args - args
 */
export const createBusinessError = (args: Omit<BusinessErrorData, 'type'>) =>
  createError({
    fatal: true,
    data: {
      type: BUSINESS_ERROR,
      ...args,
    },
  });

/**
 * 業務エラーの取得を試みる。取得できない場合はundefinedを返却する。
 * @param error - エラー情報
 */
export const tryGetBusinessError = (error: unknown): BusinessErrorData | undefined =>
  tryGetErrorData(error, BUSINESS_ERROR);
