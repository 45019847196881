import { createMaintenanceError } from '@/functions/maintenance/views';
import { MaintenancePattern } from '@/functions/maintenance';
import { filterMaintenance } from '@/functions/maintenance/views/utils';

import { defineNuxtRouteMiddleware } from '#imports';

export default defineNuxtRouteMiddleware(async (to) => {
  if (process.env.VITEST) return;

  const patterns = (to.meta.patterns || []) as MaintenancePattern[];

  if (patterns.length === 0) return;

  const matched = await filterMaintenance({ patterns });

  if (matched.length > 0) {
    const { layout, description, functionName } = matched[0];
    throw createMaintenanceError({
      layout,
      description,
      functionName,
    });
  }
});
