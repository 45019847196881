import { Aina } from '@/controllers/utils';

import { BusinessErrorCode } from '../enums';

/**
 * ビジネスエラー
 */
export class ServerBusinessError extends Error {
  static {
    this.prototype.name = 'BusinessError';
  }

  /**
   * NOTE:
   * errorHandlerでerrorをcatchする際に、H3側で全部のエラーをH3Errorに変換してしまう。
   * H3Errorに独自の値を持たせたい場合は、dataプロパティを持たせておくことで、H3Errorにも保持される。
   */
  public data: {
    type: 'business error';
    errorCode: BusinessErrorCode;
    aina?: Aina;
  };

  /**
   * コンストラクタ
   * @param errorCode - エラーコード
   * @param message - エラーメッセージ
   * @param aina - Aina情報
   * @param options - オプション
   */
  constructor(errorCode: BusinessErrorCode, message = 'business error', aina?: Aina, options?: ErrorOptions) {
    super(message, options);
    this.data = {
      type: 'business error',
      errorCode: errorCode,
      aina: aina,
    };
  }
}
