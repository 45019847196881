<script setup lang="ts">
import { UiButton, UiContact } from '@/components/ui';
import { ROUTES } from '@/enums';
import { BUSINESS_ERROR_CODE } from '@/functions/error';

import { ErrorIcon } from '../components';
import { BusinessErrorProps } from '../enums';
import { navigateTo } from '#app';

import { computed, useRouter } from '#imports';

const props = defineProps<Omit<BusinessErrorProps, 'layout'>>();

const router = useRouter();

const handleClickButton = () => {
  if (props.backTo) {
    router.push({ name: props.backTo });
  } else {
    navigateTo('/');
  }
};

const buttonLabel = computed(() => props.backToText ?? 'ホームへ');

const messagesColor = computed(() => ({ red: !props.title }));
</script>

<template>
  <div class="page">
    <div :class="['content', errorCode === BUSINESS_ERROR_CODE.SERVICE_UNAVAILABLE && 'add-gap']">
      <div class="heading">
        <ErrorIcon :title="title" />
        <div class="messages" :class="messagesColor">
          <p>{{ message }}</p>
          <p v-if="errorCode !== BUSINESS_ERROR_CODE.SERVICE_UNAVAILABLE">（{{ errorCode }}）</p>
        </div>
      </div>
      <UiContact />
    </div>
    <div class="footer-button">
      <UiButton
        v-if="errorCode !== BUSINESS_ERROR_CODE.SERVICE_UNAVAILABLE"
        variant="secondary"
        @click="handleClickButton"
        >{{ buttonLabel }}</UiButton
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import './assets/scss/mediaQuery.scss';
.page {
  height: 100dvh;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 63px;
  padding-bottom: 51px;
  @include pc {
    height: calc(100% - 122px);
  }
  &.add-gap {
    padding-top: 133px;
  }
}
.heading {
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 27px;
}
.messages {
  font-size: 1.4rem;
  white-space: pre-wrap;
  @include pc {
    white-space: normal;
    word-spacing: -0.25rem;
  }
  margin-top: 16px;
  &.red {
    color: var(--vt-text-error-color);
  }
}
.footer-button {
  text-align: center;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 72px;
}

@include pc {
  :deep(.ui-contact) {
    width: 612px;
  }
}
</style>
