export const appBaseURL = "/"

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1.0"},{"charset":"utf-8"},{"http-equiv":"Content-Type","content":"text/html; charset=UTF-8"},{"name":"author","content":""}],"link":[],"style":[],"script":[{"src":"https://cdn-blocks.karte.io/8774e9a79d3921a7ed0d1556ebc2ed5a/builder.js"}],"noscript":[],"htmlAttrs":{"lang":"ja","prefix":"og: http://ogp.me/ns#"},"charset":"utf-8","viewport":"width=device-width, initial-scale=1.0"}

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false