/**
 * H3エラーに含まれるdataの情報を取得する。H3エラーではない、もしくはH3エラーだが期待するエラータイプではない場合、undefinedを返却する。
 * @param error - エラー情報
 * @param expectType - エラー情報に含まれる期待するエラータイプ
 */
export const tryGetErrorData = <T>(error: unknown, expectType: string): T | undefined => {
  if (!(error && typeof error === 'object' && 'data' in error)) return;

  // SSRで発生したエラーは、Errorインスタンスではなく、PlainObjectとして連携される。
  // かつ、PlainObjectの場合、dataはstringのままなので、parse処理を別途行う必要がある。
  const data = typeof error.data === 'string' ? _parse(error.data) : error.data;
  if (data && data.type === expectType) {
    return data as T;
  } else {
    return;
  }
};

const _parse = (from: string) => {
  try {
    return JSON.parse(from);
  } catch (error) {
    // noop
  }
};
