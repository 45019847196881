import { datadogRum } from '@datadog/browser-rum';

import { defineNuxtPlugin } from '#app';
import { useRuntimeConfig } from '#imports';

/**
 * datadogのRum用の初期化処理。
 * 環境変数からDATADOG_APPLICATION_IDとDATADOG_CLIENT_TOKENが取得できない場合はセットアップしない。
 *
 * datadogはコストを理由として、ステージングおよび本番での利用のみを想定している。
 * ローカルでは有効化せず、検証環境も基本的に無効な状態。
 * ２つの値についてはSRE側でSecretとして管理するため、検証環境で使用したい場合にはSREに依頼すること。
 */
export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  // applicationIdとclientTokenが設定されていない場合にはRUMを無効化
  // envに宣言が無いローカル環境では動作せず、k8sのConfigmapに設定がある検証環境以降では動作する
  if (config.public.datadog.applicationId && config.public.datadog.clientToken) {
    // @see https://docs.datadoghq.com/ja/real_user_monitoring/browser/#%E5%88%9D%E6%9C%9F%E5%8C%96%E3%83%91%E3%83%A9%E3%83%A1%E3%83%BC%E3%82%BF%E3%83%BC
    datadogRum.init({
      // 再ビルドの手間をなくすため原則Podの環境変数経由でパラメータを注入する
      applicationId: config.public.datadog.applicationId,
      clientToken: config.public.datadog.clientToken,
      site: config.public.datadog.site,
      service: config.public.datadog.service,
      env: config.public.datadog.env,
      version: config.public.datadog.version,
      // allowedTracingUrls: config.public.allowedTracingUrls,
      sessionSampleRate: config.public.datadog.sessionSampleRate,
      sessionReplaySampleRate: config.public.datadog.sessionReplaySampleRate,
      trackUserInteractions: config.public.datadog.trackUserInteractions,
      trackResources: config.public.datadog.trackResources,
      trackLongTasks: config.public.datadog.trackLongTasks,
      // defaultPrivacyLevel: 'mask-user-input',
      // defaultPrivacyLevel: config.public.defaultPrivacyLevel,
    });
  }
});
