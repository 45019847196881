/**
 * @see {@link https://codesandbox.io/s/github/tanstack/query/tree/main/examples/vue/nuxt3?from-embed=&file=/plugins/vue-query.ts}
 */

import { VueQueryPlugin, QueryClient, hydrate, dehydrate } from '@tanstack/vue-query';

import type { DehydratedState, VueQueryPluginOptions, DefaultOptions } from '@tanstack/vue-query';

// Nuxt 3 app aliases
import { useState } from '#app';
import { defineNuxtPlugin } from '#imports';

export default defineNuxtPlugin((nuxt) => {
  const vueQueryState = useState<DehydratedState | null>('vue-query');

  const defaultOptions: DefaultOptions =
    process.env.MODE === 'test'
      ? {
          // APIクライアントの引数の検証や、APIレスポンスのパターンを切り替えたいので
          // テストコードではキャッシュは一律無効にする。
          queries: {
            cacheTime: 0,
            staleTime: 0,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: 0,
          },
        }
      : {
          queries: {
            // キャッシュする時間。
            cacheTime: 1000 * 60 * 5,
            // キャッシュがstaleがどうか判断する時間
            staleTime: 5000,
            /**
             * 以降はキャッシュがstaleしているとき、revalidateする設定。
             */
            // Windowが再度フォーカスされた場合(eg 別タブから戻ってきた場合)
            refetchOnWindowFocus: false,
            // ネットワークに再接続した場合(eg オフラインから復帰した場合)
            refetchOnReconnect: false,
            // エラー発生時にリトライする回数
            retry: 0,
          },
        };

  const queryClient = new QueryClient({
    defaultOptions,
  });
  const options: VueQueryPluginOptions = { queryClient };

  nuxt.vueApp.use(VueQueryPlugin, options);

  if (process.server) {
    nuxt.hooks.hook('app:rendered', () => {
      vueQueryState.value = dehydrate(queryClient);
    });
  }

  if (process.client) {
    nuxt.hooks.hook('app:created', () => {
      hydrate(queryClient, vueQueryState.value);
    });
  }
});
