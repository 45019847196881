<script setup lang="ts">
import { UiButton, UiGlobalHeader, UiIcon } from '@/components/ui';

const emit = defineEmits<{
  (e: 'clearError'): void;
}>();

const handleClickButton = () => {
  emit('clearError');
};
</script>

<template>
  <NuxtLayout>
    <div class="page">
      <UiGlobalHeader title="エラー" />
      <main class="body">
        <div class="content">
          <div class="heading">
            <div>
              <UiIcon size="80" name="failure" />
              <p class="icon-title">予期せぬ問題が発生しました</p>
            </div>
            <div class="messages">
              <p>しばらくしてからやり直してください。</p>
            </div>
          </div>
          <div class="footer">
            <UiButton @click="handleClickButton">ホームへ</UiButton>
          </div>
        </div>
      </main>
    </div>
  </NuxtLayout>
</template>

<style src="./assets/scss/main.scss"></style>
<style lang="scss" scoped>
:deep(.header) {
  background-color: var(--vt-brand-primary-color);
  box-shadow: none;
}
.page {
  height: 100dvh;
}
.body {
  height: calc(100% - 44px);
}
.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 72px;
}
.heading {
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.icon-title {
  margin-top: 18px;
  font-size: 1.8rem;
  font-weight: 500;
  color: var(--vt-text-error-color);
}
.messages {
  margin-top: 16px;
  font-size: 1.4rem;
}
.footer {
  width: 100%;
  text-align: center;
}
</style>
